import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CitationService, CitationVoidService } from '../_services';
import { first } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

@Component({
  selector: 'app-citation-void-void',
  templateUrl: './citation-void-void.component.html',
  styleUrls: ['./citation-void-void.component.scss']
})
export class CitationVoidVoidComponent implements OnInit {

  loading = false;
  data: any = {};
  ID;
  penalties: any;
  total: number; // from penalties com
  files;
  voidReasonsList: [] = [];
  dataModel = {
    "citations": [
      {
        "id": 0,
        "citationPenaltiesIds": []
      }
    ],
    "reason": "",
    "reasonId": 0,
    "settlementChannel": "ENFORCEMENT_WEB"
  };

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _citationVoidService: CitationVoidService,
    private _dialog: MatDialog

  ) {
    this.ID = this._activatedRoute.snapshot.params.id;
    this._loadCitation(this.ID);

  }

  ngOnInit(): void {
    // console.log(`CitationControlComponent ID ${this.ID}, data  `, this.data);
    this._getVoidReasonsList();
  }

  private _loadCitation(ids) {
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;
        },
        error => {
        });
  }

  getPenalities($event) {
    this.penalties = $event;
    return $event;
  }
  getFiles($event) {
    this.files = $event;
  }
  getTotal($event) {
    this.total = $event;
    return $event;
  }

  onApply() {
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      // height: '500px',
      data: {
        title: { title: "Void Citation", color: "#D93636" },
        body: `Please enter and confirm a void
        reason description`,
        btnConfirmation: { title: "Void Citation", color: "#D93636" },
        btnClose: { title: "Cancel" },
        comment: true,
        list: this.voidReasonsList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.comment)
          this.penalties.citationRequests.map((citationRequest) => {
            this.dataModel.citations[0].id = citationRequest.id;
            citationRequest.penaltiesRequestList.map((penaltiesRequest) => {
              if (penaltiesRequest.status === "Opened" && penaltiesRequest.penActionType === true)
                this.dataModel.citations[0].citationPenaltiesIds.push(penaltiesRequest.cit_Pen_Id)
              return penaltiesRequest;
            });
          });
        this.dataModel.reason = result.data.comment;
        this.dataModel.reasonId = result.data.selected.id;

        this.dataModel.settlementChannel = "ENFORCEMENT_WEB";

        console.log("this.dataModel ===== ", this.dataModel);
        this._citationVoidService.partialVoidCitations(this.dataModel).pipe(first())
          .subscribe(
            data => {
              console.log("update payCitation  data =========", data);
              // this.loading = false;
              // this._router.navigateByUrl(`/enforcement-citations`);
              if (this.files !== undefined && this.files.length > 0) {
                const formData = new FormData();
                this.files.map(file => {
                  formData.append('files', file);
                });
                this._citationService.postFiles(this.ID, formData).pipe(first())
                  .subscribe(
                    data => {
                      console.log("update payCitation  data =========", data);
                      this.loading = false;
                      this._router.navigateByUrl(`/enforcement-citations`);
                    },
                    error => {
                      console.log("update payCitation err ===== ", error);
                      this.loading = false;
                    });
              } else {
                this._router.navigateByUrl(`/enforcement-citations`);
              }
            },
            error => {
              console.log("update payCitation err ===== ", error);
              this.loading = false;
            });
      }
    });

  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

  private _getVoidReasonsList() {
    this._citationService.getVoidReasons().subscribe(res => {
      this.voidReasonsList = res.payload;
    })
  }

  get getCustomValidation() {
    if (this.penalties === undefined)
      return false;
    let isValid: boolean = false;
    this.penalties.citationRequests.map((citationRequest) => {
      citationRequest.penaltiesRequestList.map((penaltiesRequest) => {
        if (penaltiesRequest.status === "Opened" && penaltiesRequest.penActionType === true)
          isValid = true;
        return penaltiesRequest;
      });
    });
    return isValid
  }


}
