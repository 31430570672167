<div class="p-0 mx-0 pb-5 mb-5">
    <div class="dialog-header d-flex justify-content-between py-3">
        <h1 class="h3 fw-bold">Tow Requests Report</h1>
    </div>

    <form class="custom-inputs mt-0" [formGroup]="filtersForm" (ngSubmit)='applyFilter()'>
        <div class="row pt-4 pb-0 d-flex">
            <div class="row">
                <div class="col-md-3 custom-inputs mx-0">
                    <label> Operation </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select [(value)]="selectedOperationId" placeholder="Operation" formControlName="operation"
                            (selectionChange)="getWorkforceByOperationId(selectedOperationId)">
                            <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 custom-inputs mx-0">
                    <label> Employee </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select [(ngModel)]="enforcerId" formControlName="enforceName" placeholder="Employee Name"
                            #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search formControlName="enforceNameSearch"
                                    placeholderLabel="Settled By">

                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option [hidden]="selectedWorkForceIds.length >= 1" [value]="'0'">{{'All'}}</mat-option>
                            <mat-option [hidden]="selectedWorkForceIds.length <= 1" [value]="'00'">{{'More
                                Than1'}}</mat-option>
                            <mat-option *ngFor="let enforcer of workforcesList" [value]="enforcer.id">
                                <mat-checkbox (change)="choseWorker($event,enforcer)" [value]="enforcer?.id">
                                    {{enforcer?.name}}
                                </mat-checkbox>
                                {{enforcer.id}}-{{enforcer.name}}
                            </mat-option>
                            <div class="clear-btn" *ngIf="this.selectedWorkForceIds.length>0">
                                <button w Tmat-raised-button (click)="clearEnforcer()">
                                    Clear
                                </button>
                            </div>
                        </mat-select>
                    </mat-form-field>


                    <!-- <mat-form-field>
                        <mat-select [(ngModel)]="enforcerId" formControlName="enforceName" placeholder="Enforcer Name"
                          #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search formControlName="enforceNameSearch" placeholderLabel="Settled By">

                            </ngx-mat-select-search>
                          </mat-option>

                          <mat-option [hidden]="selectedWorkForceIds.length >= 1" [value]="'0'">{{'All'}}</mat-option>
                          <mat-option [hidden]="selectedWorkForceIds.length <= 1" [value]="'00'">{{'More Than1'}}</mat-option>

                          <mat-option *ngFor="let enforcer of filteredEnforcerOptions | async" [value]="enforcer.id">
                            <mat-checkbox [checked]="enforcer.value" [value]="enforcer?.id"
                              (change)="choseWorker($event,enforcer)">
                              {{enforcer?.name}}
                            </mat-checkbox>
                            {{enforcer.id}}-{{enforcer.name}}
                          </mat-option>
                          <div class="clear-btn" *ngIf="this.selectedWorkForceIds.length>0">
                            <button mat-raised-button (click)="clearEnforcer()">
                              Clear
                            </button>
                          </div>
                        </mat-select>
                      </mat-form-field> -->
                </div>
                <div class="col-md-3 custom-inputs mx-0">
                    <label for="startDate">
                        Start Date
                    </label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [matDatepicker]="startDate" placeholder='Start Date' formControlName="startDate"
                            id="startDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-3 custom-inputs mx-0">
                    <label for="endDate">End Date</label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [min]="filtersForm.value.startDate" placeholder='End Date'
                            [matDatepicker]="endDate" formControlName="endDate" id="endDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>

                    </mat-form-field>
                </div>



            </div>

            <div class="row">
                <div class="col-md-3 custom-inputs mx-0">
                    <label for="plateNumber">Plate No</label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <input matInput placeholder='ex. ABC 1234' formControlName="plateNumber" id="plateNumber" />
                    </mat-form-field>
                </div>
                <div class="col-md-3 custom-inputs mx-0">
                    <label> Status </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select name="status" formControlName="status" placeholder='status'>
                            <mat-option [value]="null">{{'All'}}</mat-option>
                            <mat-option *ngFor="let status of statusList" [value]="status">{{ status }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-5">
                    <div class="d-flex row justify-content-center mt-2">
                        <button [disabled]="loading" type='submit'
                            class="btn search-btn mt-4 col-md-5 h4 text-center fw-bold text-white">Search</button>
                        <button type='button'
                            class="btn filters-btn-clear h4 text-center mx-1 mt-4 col-md-5 text-white fw-bold"
                            (click)="onClearFilters()">Clear</button>
                    </div>
                </div>

            </div>
        </div>


    </form>



    <section class="mb-5">
        <div class="mat-elevation-z8 custom-table-card mb-5">
            <mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="vehiclePlateNumberEn">
                    <mat-header-cell *matHeaderCellDef>Vehicle Plate No</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{slicePlateNumber(element.vehiclePlateNumberEn)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef>Creation Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.createdDate |date:'MM/dd/yyyy, h:mm a'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="requestStatus">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.requestStatus }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="evidence">
                    <mat-header-cell *matHeaderCellDef> Evidence </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a *ngIf="element?.images?.length > 0"  (click)="openImageCarousel(element?.images)" class="image-anchor cursor-pointer">{{element?.images?.length}}
                            Images</a>
                            <span *ngIf="element?.images?.length == 0" >No Images</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef>Request Assigne</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        LoadingData
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        NoData
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                    [class.expanded-row]="row.isExpanded">
                </mat-row>

            </mat-table>
            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <!-- List Grid -->
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>

</div>
<ng-template #imgCarousel style='overflow-y: auto;padding:4px;'>
    <!-- <ngx-gallery [images]="currentImages" [options]="galleryOptions" (imageClick)="onImageClick($event)">
    </ngx-gallery> -->
    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="7000" color="primary" maxWidth="auto"
        proportion="100" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
        [useMouseWheel]="false" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of currentImages; let i = index" [image]="slide"
            overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
</ng-template>