import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { CitationService } from '../../_services';
@Component({
  selector: 'app-citation-penalties',
  templateUrl: './citation-penalties.component.html',
  styleUrls: ['./citation-penalties.component.scss']
})
export class CitationPenaltiesComponent implements OnInit {
  @Input() data
  @Input() penActionType = 'control';
  @Input() isHeader = true;
  @Input() isComment = false;
  @Input() voidReasonsList = [];
  @Input() vat = 0;
  @Output() penalties = new EventEmitter();
  @Output() total = new EventEmitter();


  citationPenalties

  loading = false;
  submitted = false;

  saveForm: FormGroup;
  count = new FormControl(1, Validators.min(1));

  private _penActionType = 'Settled';

  constructor(private fb: FormBuilder,
    private CitationService: CitationService) {
  }
  citationCount: number=1;

  getCitationCount(id: number):any {
    this.CitationService.getCitationCount(id)
      .pipe(first())
      .subscribe(
        resp => {
          this.citationCount = resp;
          console.log('resp.payload', this.citationCount );
          return resp
        });
  }

  ngOnInit() {
    console.log("this penActionType", this.penActionType);
    this.getCitationCount(this.data.id);
      switch (this.penActionType) {
        case 'voided':
          this._penActionType = 'Voided';
          break;
      }
      let _filterdData = this.data;
      _filterdData.citationPenalties = this.data.citationPenalties.filter(item => item.status === "Opened");
      const _penaltiesRequestList = _filterdData.citationPenalties.map((item) => this.initPenalties(item.cit_pen_Id));
      // console.log("_penaltiesRequestList", _penaltiesRequestList);
      this.saveForm = this.fb.group({
        id: [this.data.id, []],
        penaltiesRequestList: this.fb.array(_penaltiesRequestList),
      });
      setTimeout(() => {
        this.onSubmit();
      }, 100);

      this.saveForm.get('penaltiesRequestList').valueChanges.subscribe(val => {
        this.onSubmit();
      });
  }
  get f() { return this.saveForm.controls; }

  onSubmit() {
    const penaltiesRequestList = this.f.penaltiesRequestList.value;

    const data = {
      "citationRequests": [
        {
          "id": this.f.id.value,
          penaltiesRequestList
        }
      ]
    };
    this.penalties.emit(data);
    console.log(this.getTotal());
    
    this.total.emit(this.getTotal());
  }

  initPenalties(cit_pen_Id) {
// 
    return this.fb.group({ // make a nested group
      cit_Pen_Id: [cit_pen_Id],
      count: [this.citationCount , Validators.min(1)],
      penActionType: [this._penActionType],
      comment: [''],
      reasonId: [''],
    });
  }

  getTotal() {
    const penaltiesRequestList = this.f.penaltiesRequestList.value;
    let total = 0;
    penaltiesRequestList.map((item) => {
      const penData = this.data.citationPenalties.filter(pen => pen.cit_pen_Id === item.cit_Pen_Id);
      if (item.penActionType == 'Settled')
        total += item.count * penData[0].operationViolationPenalty.penalty.fees;
    });
    return total + (total * this.vat);
  }
  getPenActionControlValue(i) {
    return this.f.penaltiesRequestList.value[i].penActionType;
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.saveForm.controls[controlName].hasError(errorName) : false;
  }
}
