
import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class CitationVoidService {
    constructor(handler: HttpBackend, private _http: HttpClient) { }
    getList(offset: number, limit: number,
        sortBy: string, sortDirection: string,
        date,
        hasEmailAction,
        query: string = '',
    ) {
        let api = `${environment.baseURL}enforcements/citations/unpaidCitations/partialVoid?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection.toUpperCase()}&date=${date}&hasEmailAction=${hasEmailAction}`;
        if (query)
            api += `&query=${query}`;
        console.log('CitationVoidService getList api', api);
        return this._http.get<any>(api);
    }

    partialVoidCitations(data) {
        return this._http.put(`${environment.baseURL}enforcements/citations/partialVoidCitations`, data);
    }

}
